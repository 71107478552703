<template>
  <div class="rankingList-listContent">
    <div class="grid-content bg-purple-dark">
      <el-tabs v-model="activeName" @tab-click="handleClick">
        <span
          @click="toDetail"
          class="lj"
          style="color: #0099fa; cursor: pointer"
          v-if="showDetai"
          >详情</span
        >
        <el-tab-pane label="欠费道路/车场" name="first">
          <!--<router-link style="color:#0099FA;" :to="{path:path}">详情</router-link>-->
          <el-row
            v-for="(item, index) in arrearageParking"
            :key="index"
            class="row"
          >
            <el-col class="col">
              <span
                :style="{
                  background:
                    index == 0
                      ? 'url(' + ranking1 + ')no-repeat'
                      : index == 1
                      ? 'url(' + ranking2 + ')no-repeat'
                      : index == 2
                      ? 'url(' + ranking3 + ')no-repeat'
                      : 'white',
                }"
                class="indexNumber"
                >{{ index > 2 ? index + 1 : "" }}</span
              >
              <span class="item">{{ item.parkName }}</span>
              <span
                class="itemMoney"
                :style="{ color: index < 3 ? '#F7B62D' : '' }"
                >{{
                  priceFormate(
                    ![null, '', undefined].includes(item.arrearageAmount) ? item.arrearageAmount : item.money
                  )
                }}</span
              >
            </el-col>
          </el-row>
        </el-tab-pane>
        <el-tab-pane label="欠费车牌" name="second">
          <!--<router-link style="color:#0099FA;" :to="{path:path}">详情</router-link>-->
          <el-row
            v-for="(item, index) in arrearagePlate"
            :key="index"
            class="row"
          >
            <el-col class="col">
              <span
                :style="{
                  background:
                    index == 0
                      ? 'url(' + ranking1 + ')no-repeat'
                      : index == 1
                      ? 'url(' + ranking2 + ')no-repeat'
                      : index == 2
                      ? 'url(' + ranking3 + ')no-repeat'
                      : 'white',
                }"
                class="indexNumber"
                >{{ index > 2 ? index + 1 : "" }}</span
              >
              <span class="item">{{
                item.plateNumber ? item.plateNumber : item.palteNumber
              }}</span>
              <span
                class="itemMoney"
                :style="{ color: index < 3 ? '#F7B62D' : '' }"
                >{{
                  priceFormate(
                    ![null, '', undefined].includes(item.arrearageAmount) ? item.arrearageAmount : item.money
                  )
                }}</span
              >
            </el-col>
          </el-row>
        </el-tab-pane>
      </el-tabs>
    </div>
  </div>
</template>
<script>
import ranking1 from "@/assets/img/ranking1.png";
import ranking2 from "@/assets/img/ranking2.png";
import ranking3 from "@/assets/img/ranking3.png";
export default {
  props: {
    arrearageParking: {
      default: function () {
        // 欠费车场
        return [];
      },
    },
    arrearagePlate: {
      default: function () {
        // 欠费车牌
        return [];
      },
    },
    showDetai: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      activeName: "first",
      path: "/arrearageParkingList",
      ranking1: ranking1,
      ranking2: ranking2,
      ranking3: ranking3,
    };
  },
  methods: {
    priceFormate(val) {
      if (val == 0) {
        return 0;
      } else {
        let price = Number(val / 100).toFixed(2);
        price =
          price.split(".")[0].replace(/(?=(\B)(\d{3})+$)/g, ",") +
          "." +
          price.split(".")[1];
        return price;
      }
    },
    toDetail() {
      this.$setAuthority("/arrearageParkingList", this.$route.meta.authority);
      this.$router.push(this.path);
    },
    handleClick() {
      if (this.activeName === "first") {
        this.path = "/arrearageParkingList";
      } else {
        this.path = "/arrearagePlateList";
      }
    },
  },
};
// 						background:url(../../assets/img/ranking1.png);
</script>
<style lang="stylus" rel="stylesheet/stylus">
.rankingList-listContent {
  .el-tabs__content {
    overflow: visible !important;
  }
}
</style>
<style scoped lang="stylus" rel="stylesheet/stylus">
.rankingList-listContent {
  .grid-content {
    height: 328px;
    overflow-y: auto;

    .lj {
      float: right;
      margin-top: -40px;
    }

    .row:nth-last-child(1) {
      border-bottom: none;
      margin: 0 0;
    }

    .row {
      width: 100%;
      margin: 10px 0;
      border-bottom: 1px solid #EFE5F9;
      height: 40px;
      line-height: 18px;
      color: #333333;

      .col {
        width: 100%;

        .indexNumber {
          display: inline-block;
          width: 29px;
          height: 34px;
          line-height: 35px;
          text-align: center;
          margin-right: 10px;
          font-size: 15px;
        }

        .item {
          font-size: 15px;
          position: absolute;
          top: 8px;
          max-width: 200px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }
    }
  }

  .itemMoney {
    float: right;
    font-size: 15px;
    height: 34px;
    line-height: 35px;
  }
}
</style>
